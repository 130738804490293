<template>
  <div>
    <section class="grid grid-cols-1 w-full ">
      <video class="w-full h-auto object-cover" autoplay loop muted playsinline>
        <source src="/video/newseaton-hero.mp4" type="video/mp4" />
        Your browser does not support HTML video.
      </video>
    </section>

    <section
      class="bg-[url('/img/bg-lightblue.jpg')] bg-cover bg-center md:py-32 py-16"
    >
      <div class="container mx-auto px-4 grid grid-cols-1 gap-6 text-center">
        <!-- Headline -->
        <div>
          <h2 class="text-3xl md:text-5xl font-bold">
            FREEHOLD TOWNHOMES
          </h2>
          <h3 class="text-xl md:text-3xl font-bold mt-1">
            FROM THE
          </h3>
          <h4 class="text-4xl md:text-8xl font-bold">
            $800's
          </h4>
        </div>

        <!-- Paragraph -->
        <p class="text-base md:text-lg leading-relaxed max-w-4xl mx-auto">
          Pickering's prestigious and previously sold-out master-planned
          community returns with a new release coming soon! Discover a
          neighbourhood created for families from the ground up, and find the
          home you've been looking for in North Pickering's most connected
          location. Enjoy a wide range of Contemporary and Traditional
          family-sized townhomes with flexible layouts suited to your lifestyle
          in a complete neighbourhood setting. Take advantage of 42 community
          parks, 3,750 acres of trails, 3 new elementary schools, and over 200
          retailers all within New Seaton — and just 10 minutes to Highways 401,
          404 and GO Transit. Register now for exclusive community information
          and be first to own during the new release!
        </p>

        <!-- Button -->
        <div>
          <a
            href="#register"
            class="inline-block bg-black text-white font-black py-3 px-10 mt-3 hover:bg-gray-800 transition-colors duration-300 text-2xl"
          >
            REGISTER NOW
          </a>
        </div>
      </div>
    </section>

    <section>
      <img src="/img/townhomes.gif" alt="Townhomes" class="w-full" />
    </section>

    <section class="md:py-32 py-16 bg-white text-black">
      <div class="container mx-auto px-4">
        <!-- Top Headings -->
        <div class="text-center mb-12">
          <h2 class="text-3xl md:text-7xl font-bold">
            BUILT FROM THE GROUND UP
          </h2>
          <p class="text-xl md:text-4xl mt-2">
            EXPLORE THIS AMAZING COMMUNITY FOR YOURSELF
          </p>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 items-center pt-10">
          <!-- Left Content -->
          <div>
            <h3 class="text-3xl md:text-7xl font-bold max-w-2xl">
              PICKERING’S FINEST LIFESTYLE BUILDERS
            </h3>
            <p class="mt-4 text-base md:text-lg leading-relaxed max-w-xl">
              Bringing the craftsmanship and care of three award-winning
              homebuilders to the GTA's most prominent neighbourhoods. New
              Seaton combines new and exquisite architecture, unmatched interior
              décor, and decades of homebuilding expertise to create a unique
              family community built from the ground up with new and exquisite
              architecture, exceptional interior décor, and decades of
              homebuilding expertise.
            </p>
          </div>

          <!-- Right Image -->
          <div>
            <img
              src="/img/ns-flower.png"
              alt="Colorful leaf design"
              class="w-full h-auto mx-auto md:mx-0 max-w-md"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- New Section for Ontario’s Largest Master-Planned Community -->
    <section class="md:py-32 py-16 bg-[#D8D8D8] text-black">
      <div class="container mx-auto px-4">
        <!-- Section Heading -->
        <div class="text-center mb-20">
          <h2 class="text-2xl md:text-5xl font-bold">
            ONTARIO’S LARGEST <br />
            MASTER-PLANNED COMMUNITY
          </h2>
        </div>

        <!-- Grid of Features (2 columns at all sizes) -->
        <div class="grid grid-cols-2 gap-10 text-center ">
          <!-- Feature 1 -->
          <div class="flex flex-col items-end">
            <div>
              <div class="overflow-hidden mb-3">
                <img
                  src="/img/parks.png"
                  alt="Park with bridge"
                  class="w-[300px] h-full object-cover"
                />
              </div>
              <p
                class="text-[#E30033] font-bold text-sm md:text-lg"
                style="line-height: 1.1;"
              >
                WITH 42 <br />
                DEDICATED <br />
                COMMUNITY PARKS
              </p>
            </div>
          </div>

          <!-- Feature 2 -->
          <div class="flex flex-col items-start">
            <div>
              <div class="overflow-hidden mb-3">
                <img
                  src="/img/trails.png"
                  alt="Park with bridge"
                  class="w-[300px] h-full object-cover"
                />
              </div>
              <p
                class="text-[#E30033] font-bold text-sm md:text-lg"
                style="line-height: 1.1;"
              >
                3,750 ACRES OF TRAILS<br />
                WITH DEDICATED <br />
                BICYCLE PATHS
              </p>
            </div>
          </div>

          <!-- Feature 3 -->
          <div class="flex flex-col items-end">
            <div>
              <div class="overflow-hidden mb-3">
                <img
                  src="/img/retail.png"
                  alt="Park with bridge"
                  class="w-[300px] h-full object-cover"
                />
              </div>
              <p
                class="text-[#E30033] font-bold text-sm md:text-lg"
                style="line-height: 1.1;"
              >
                A PROPOSED RETAIL <br />
                CENTRE WITH <br />
                OVER 200 RETAILERS
              </p>
            </div>
          </div>

          <!-- Feature 4 -->
          <div class="flex flex-col items-start">
            <div>
              <div class="overflow-hidden mb-3">
                <img
                  src="/img/schools.png"
                  alt="Park with bridge"
                  class="w-[300px] h-full object-cover"
                />
              </div>
              <p
                class="text-[#E30033] font-bold text-sm md:text-lg"
                style="line-height: 1.1;"
              >
                PROPOSED <br />
                SCHOOLS &<br />
                RECREATION CENTRES
              </p>
            </div>
          </div>

          <!-- Feature 5 -->
          <div class="flex flex-col items-end">
            <div>
              <div class="overflow-hidden mb-3">
                <img
                  src="/img/grocery.png"
                  alt="Park with bridge"
                  class="w-[300px] h-full object-cover"
                />
              </div>
              <p
                class="text-[#E30033] font-bold text-sm md:text-lg"
                style="line-height: 1.1;"
              >
                NEARBY GROCERY,<br />
                RESTAURANTS, <br />
                AND BUSINESSES
              </p>
            </div>
          </div>

          <!-- Feature 6 -->
          <div class="flex flex-col items-start">
            <div>
              <div class="overflow-hidden mb-3">
                <img
                  src="/img/highways.png"
                  alt="Park with bridge"
                  class="w-[300px] h-full object-cover"
                />
              </div>
              <p
                class="text-[#E30033] font-bold text-sm md:text-lg"
                style="line-height: 1.1;"
              >
                CONNECT TO <br />
                HIGHWAYS 401 &amp; 407<br />
                AND THE GO TRANSIT
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-[#94CDE7] py-16 text-black">
      <div class="container mx-auto px-4">
        <!-- Section Heading -->
        <h2 class="text-center text-2xl md:text-4xl font-bold mb-8">
          DESIGNER DECORATED MODEL HOMES TO TOUR
        </h2>

        <!-- Slick Slider -->
        <slick :options="slickOptions" class="max-w-6xl mx-auto">
          <!-- Dynamically render slides -->
          <div
            v-for="(image, index) in galleryImages"
            :key="index"
            class="px-2"
          >
            <!-- Slide background container -->
            <div
              class="md:h-[300px] h-[280px] w-full bg-center bg-cover bg-no-repeat flex items-center justify-center"
              :style="{ backgroundImage: `url(${image})` }"
            >
              <!-- If you want text or any overlay in the middle, you can add it here -->
              <!-- <p class="text-white font-bold">Slide {{ index + 1 }}</p> -->
            </div>
          </div>
        </slick>
      </div>
    </section>

    <section id="register" class="bg-white md:py-32 py-16 text-black">
      <div class="container mx-auto px-4">
        <!-- Heading -->
        <div class="text-center mb-14">
          <h2 class="text-2xl md:text-4xl font-bold">REGISTER TO BE FIRST</h2>
        </div>

        <!-- Updated Form with Vue data-binding and @submit.prevent -->
        <form class="mx-auto" @submit.prevent="submitForm">
          <!-- Grid for text fields -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <!-- First Name -->
            <div>
              <input
                v-model="form.firstName"
                type="text"
                placeholder="FIRST NAME*"
                class="w-full bg-[#E6E6E6] px-4 py-3 text-sm md:text-base placeholder-black outline-none"
                required
              />
            </div>
            <!-- Last Name -->
            <div>
              <input
                v-model="form.lastName"
                type="text"
                placeholder="LAST NAME*"
                class="w-full bg-[#E6E6E6] px-4 py-3 text-sm md:text-base placeholder-black outline-none"
                required
              />
            </div>

            <!-- Email Address -->
            <div>
              <input
                v-model="form.email"
                type="email"
                placeholder="EMAIL ADDRESS*"
                class="w-full bg-[#E6E6E6] px-4 py-3 text-sm md:text-base placeholder-black outline-none"
                required
              />
            </div>
            <!-- Phone Number -->
            <div>
              <input
                v-model="form.phone"
                type="tel"
                placeholder="PHONE NUMBER*"
                class="w-full bg-[#E6E6E6] px-4 py-3 text-sm md:text-base placeholder-black outline-none"
                required
              />
            </div>

            <!-- Postal Code -->
            <div>
              <input
                v-model="form.postalCode"
                type="text"
                placeholder="POSTAL CODE*"
                class="w-full bg-[#E6E6E6] px-4 py-3 text-sm md:text-base placeholder-black outline-none"
                required
              />
            </div>
            <!-- Type of Buyer (Select) -->
            <div>
              <select
                v-model="form.buyerType"
                class="w-full bg-[#E6E6E6] px-4 py-3 text-sm md:text-base placeholder-black outline-none custom-select-arrow"
                required
              >
                <option value="" disabled>TYPE OF BUYER*</option>
                <option value="first-time">First-Time Buyer</option>
                <option value="move-up">Move-Up Buyer</option>
                <option value="downsize">Downsizer</option>
              </select>
            </div>

            <!-- How did you hear about us? -->
            <div>
              <input
                v-model="form.referral"
                type="text"
                placeholder="HOW DID YOU HEAR ABOUT US?*"
                class="w-full bg-[#E6E6E6] px-4 py-3 text-sm md:text-base placeholder-black outline-none"
                required
              />
            </div>
            <!-- Are you a Realtor? (Select) -->
            <div>
              <select
                v-model="form.isRealtor"
                class="w-full bg-[#E6E6E6] px-4 py-3 text-sm md:text-base placeholder-black outline-none custom-select-arrow"
                required
              >
                <option value="" disabled>ARE YOU A REALTOR?*</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>

          <!-- Checkbox, Disclaimer Text, and Button -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6 items-start">
            <!-- Checkbox + Disclaimer Text -->
            <div class="flex items-start gap-2">
              <input
                v-model="form.consent"
                type="checkbox"
                id="consent"
                class="mt-1 w-4 h-4 cursor-pointer accent-black"
                required
              />
              <label for="consent" class="text-sm md:text-base leading-normal">
                By completing this registration form for New Seaton, I expressly
                provide my consent to receive electronic messages from New
                Seaton, and from the builders of New Seaton, retroactively,
                today and in the future and for any projects. I understand that
                I may withdraw my consent by unsubscribing at any time.
              </label>
            </div>

            <!-- Register Button -->
            <div class="flex md:justify-end md:mt-0">
              <button
                type="submit"
                class="inline-block bg-black text-white font-black py-3 px-20 mt-3 hover:bg-gray-800 transition-colors duration-300 md:text-2xl md:w-[400px] w-full"
              >
                REGISTER NOW
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>

    <section class="bg-black text-white py-20">
      <div class="container mx-auto px-4">
        <div class="grid grid-cols-1 md:grid-cols-12 items-center">
          <!-- Left: Logos + Disclaimers -->
          <div
            class="flex flex-col md:col-span-9 items-center md:items-start justify-center"
          >
            <!-- Logos row -->
            <div class="flex flex-wrap md:justify-start justify-center gap-10">
              <!-- Aspen Ridge -->
              <div class="text-left">
                <div class="min-h-[70px]">
                  <img
                    src="/img/aspen-logo-blk.png"
                    alt="AspenRidge Homes"
                    class="w-[200px] block"
                  />
                </div>
                <div class="mt-2">
                  <p class="font-black">Aspen Ridge</p>
                  <p>Saturday &amp; Sunday: 11-5 pm</p>
                  <p>Monday to Wednesday: 1-6 pm</p>
                  <p>Thursday &amp; Friday: By Appointment</p>

                  <p class="underline">
                    <a href="mailto:NSSales@aspenridgehomes.com" class="">
                      NSSales@aspenridgehomes.com
                    </a>
                  </p>
                </div>
              </div>

              <!-- Brookfield Residential -->
              <div class="text-left">
                <div class="min-h-[70px]">
                  <img
                    src="/img/bf-logo-blk.png"
                    alt="Brookfield Residential"
                    class="w-[200px] block"
                  />
                </div>
                <div class="mt-2">
                  <p class="font-black">Brookfield Residential</p>
                  <p>By Appointment Only</p>
                  <p class="underline">
                    <a href="mailto:newseaton@brookfieldrp.com">
                      newseaton@brookfieldrp.com
                    </a>
                  </p>
                </div>
              </div>

              <!-- Tower Hill -->
              <div class="text-left">
                <div class="min-h-[70px]">
                  <img
                    src="/img/towerhill-logo-blk.png"
                    alt="TowerHill Homes"
                    class="w-[200px] block"
                  />
                </div>
                <div class="mt-2">
                  <p class="font-black">Tower Hill</p>
                  <p>Saturday &amp; Sunday: 12-5 pm</p>
                  <p>Monday to Friday: By Appointment</p>
                  <p class="underline">
                    <a href="mailto:sales@towerhill.ca">
                      sales@towerhill.ca
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <!-- Disclaimer text -->
          </div>

          <!-- Right: Map -->
          <div class="flex md:col-span-3 justify-center md:justify-end">
            <!-- Replace with your actual map image or an inline SVG layout -->
            <img
              src="/img/ns-map-blk.png"
              alt="Presentation Centre Map"
              class="w-full mt-10 md:mt-0"
            />
          </div>
        </div>
        <p class="text-xs opacity-70 mt-10 text-left">
          Renderings are artist’s concept. Prices, terms and specifications
          subject to change without notice. E. &amp; O. E.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import Slick from "vue-slick";
import jq from "jquery";

export default {
  layout: "blank",
  components: {
    Slick
  },
  data() {
    return {
      galleryImages: [
        "/img/ns-community-gallery/gallery-1.jpg",
        "/img/ns-community-gallery/gallery-2.jpg",
        "/img/ns-community-gallery/gallery-3.jpg",
        "/img/ns-community-gallery/gallery-4.jpg",
        "/img/ns-community-gallery/gallery-5.jpg",
        "/img/ns-community-gallery/gallery-6.jpg",
        "/img/ns-community-gallery/gallery-7.jpg",
        "/img/ns-community-gallery/gallery-8.jpg",
        "/img/ns-community-gallery/gallery-9.jpg",
        "/img/ns-community-gallery/gallery-10.jpg",
        "/img/ns-community-gallery/gallery-11.jpg",
        "/img/ns-community-gallery/gallery-12.jpg",
        "/img/ns-community-gallery/gallery-13.jpg",
        "/img/ns-community-gallery/gallery-14.jpg",
        "/img/ns-community-gallery/gallery-15.jpg",
        "/img/ns-community-gallery/gallery-16.jpg",
        "/img/ns-community-gallery/gallery-17.jpg",
        "/img/ns-community-gallery/gallery-18.jpg",
        "/img/ns-community-gallery/gallery-19.jpg"
      ],
      slickOptions: {
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            // Tablet and down
            breakpoint: 1024,
            settings: {
              slidesToShow: 2
            }
          },
          {
            // Mobile
            breakpoint: 768,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      },
      form: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        postalCode: "",
        buyerType: "",
        referral: "",
        isRealtor: "",
        consent: false
      }
    };
  },
  methods: {
    submitForm() {
      // Basic client-side check for the consent box
      if (!this.form.consent) {
        alert("Please consent before submitting.");
        return;
      }

      // Prepare data
      const formData = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        phone: this.form.phone,
        postalCode: this.form.postalCode,
        buyerType: this.form.buyerType,
        referral: this.form.referral,
        isRealtor: this.form.isRealtor
      };

      console.log("Submitting form:", formData);

      // jQuery AJAX request
      jq.ajax({
        method: "POST",
        url: "/php/register-form.php",
        crossDomain: true,
        data: formData,
        success: data => {
          console.log("Success:", data);
          // If you have a thank-you route
          this.$router.push("/thankyou");
        }
      }).done(msg => {
        console.log("Request done:", msg);
      });
    }
  },
  mounted() {
    // Add Tailwind CSS CDN dynamically
    if (!document.querySelector("#tailwindcdn-script")) {
      const script = document.createElement("script");
      script.id = "tailwindcdn-script";
      script.src = "https://cdn.tailwindcss.com";
      document.head.appendChild(script);
    }
  }
};
</script>

<style scoped lang="scss">
html,
body {
  scroll-behavior: smooth;
}
</style>
<style>
.slick-prev:before,
.slick-next:before {
  color: black !important;
}

/* Hide default arrows for cross-browser */
.custom-select-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Make the arrow grey using a data URI */
  background-image: url("data:image/svg+xml;charset=UTF-8,<svg fill='%237e7e7e' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='M5.516 7.548l4.484 4.487 4.484-4.487'/></svg>");
  background-repeat: no-repeat;
  background-position: right 1rem center; /* adjust to taste */
  background-size: 1.5rem; /* arrow size */
  padding-right: 2.5rem; /* extra right padding */
}

/* (Optional) Hide the IE arrow if you still support old IE or Edge Legacy */
select::-ms-expand {
  display: none;
}
</style>
